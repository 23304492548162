import React from 'react';
import styled from 'styled-components';
import WorkItemBlock from './WorkItemBlock';
import WorkItemText from './WorkItemText';

const ContainerDiv = styled.div`

`;

const TitleBar = styled.div`
    display: flow-root;
    align-items: top;

    margin-bottom: 20px;

    padding-left: 72px;
    padding-right: 72px;
    @media only screen and (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const Title = styled.div`
    font-family: 'Jost';
    color: white;
    font-weight: bold;
    font-size: 26px;

    float: left;
`;

const ExploreAll = styled.div`
    font-family: 'Jost';
    color: white;
    font-size: 22px;
    padding-bottom: 10px;
    text-decoration: underline;

    transition: color 0.5s;

    :hover {
        cursor: pointer;
        color: #12EFD4;
    }

    float: right;
`;

const WorkItemList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 45px;
`;

const TextWorkItemList = styled.div`
    // padding: 20px;
`;

export default function WorkSection() {
    return (
        <ContainerDiv>
            <TitleBar>
                <Title>Work</Title>
                {/* <ExploreAll onClick={() => {
                    console.log("I want to explore all");
                }}>Explore all</ExploreAll> */}
            </TitleBar>
            {/* <WorkItemList>
                <WorkItemBlock title='Brave Campaign / Winter 2021' text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id nibh tortor id aliquet lectus. Consequat nisl vel pretium lectus quam id leo.' />
                <WorkItemBlock title='Explainer Video / Winter 2021' text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id nibh tortor id aliquet lectus. Consequat nisl vel pretium lectus quam id leo.' />
            </WorkItemList> */}
            <TextWorkItemList>
                <WorkItemText key={"Influencers"} pairity='even' imageSrc={'images/work/Influencers.png'} title='Influencers' content={["Multipliers and opinion leaders play a crucial role in the success of any product and platform. DeFiChain Accelerator is building relationships with world leading influencers to bring DeFiChain to everybody."]} />
                <WorkItemText key={"Learn_and_Earn"} pairity='odd' imageSrc={'images/work/Learn_and_Earn.png'} title='Learn & Earn' content={["Knowing your product is easy when you work with it every day. But what about new customers? Our Learn & Earn campaigns help people to better understand DeFiChain and brings them one step closer to financial freedom."]} />
                <WorkItemText key={"Airdrop"} pairity='even' imageSrc={'images/work/Airdrop.png'} title='Airdrop' content={["Free money is great, but even better is bringing new people to DeFiChain. Our Airdrop campaigns are an effective marketing measure to new get people into the DeFiChain ecosystem and if they are already in the ecosystem many will try out what DeFiChain has to offer! That’s what the Airdrops are all about."]} />
                <WorkItemText key={"PR"} pairity='odd' imageSrc={'images/work/PR.png'} title='PR' content={["PR is the basis of any marketing campaign. Using media publications to convey your stories is a crucial part for any successful business. DeFiChain Accelerator is working with world leading PR agencies to do exactly that."]} />
                <WorkItemText key={"Cross"} pairity='even' imageSrc={'images/work/Cross.png'} title='Cross Channel Marketing' content={["DeFiChain Accelerator team is also executing cross channel campaigns including ", { text: "Brave Ads", url: "/downloads/brave.zip" }, ", Social Media Ads, Banner Ads, Podcasts, Influencers, AMAs and many more to communicate specific topics to a broader audience."]} />
            </TextWorkItemList>
        </ContainerDiv>
    );
}
