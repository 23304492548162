import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import CopyHolder from './CopyHolder';

const ContainerDiv = styled.div`
	height: 100vh;
	min-height: 700px;
	background-color: #051322;

	display: flex;
	align-items: center;
	justify-content: center

	@media only screen and (max-width: 768px) {
		flex-direction: column;
		background-position: 0% 70%, 50% 10%;
		background-size: 100%, auto;
	}
`;

const ImagesDiv = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	opacity: 0;

	background-image: url('images/decorations/BG_deco.png'), url('images/decorations/Circles_deco.png');
	background-repeat: no-repeat, no-repeat;
	background-position: 100% 50%, 10% 50%;
	background-size: 70%, auto;
`;

interface Properties {
	scrollToContact: VoidFunction;
}

export default function LandingImage(props: Properties) {
	const wigglePercentage = 5;
	const ref: React.MutableRefObject<null | HTMLDivElement> = useRef(null);

	const handleMouseMove = useCallback((event: any) => {
		const x = (event.pageX / ref.current!.clientWidth) * 2 - 1;
		const y = (event.pageY / ref.current!.clientHeight) * 2 - 1;

		if (event.pageY > ref.current!.clientHeight) {
			return;
		}

		let newBackgroundPosition: string;
		if (ref.current!.clientWidth > 768) {
			newBackgroundPosition = `${100 + wigglePercentage * x}% ${50 + wigglePercentage * y}%, ${10 + wigglePercentage * x}% ${50 + wigglePercentage * y}%`;
		} else {
			newBackgroundPosition = `${0 + wigglePercentage * x}% ${70 + wigglePercentage * y}%, ${50 + wigglePercentage * x}% ${10 + wigglePercentage * y}%`;
		}
		setTimeout(() => {
			ref.current!.style.backgroundPosition = newBackgroundPosition;
		}, 200);
	}, [ref]);

	useEffect(() => {
		window.addEventListener('mousemove', handleMouseMove);
	});

	return (
		<ContainerDiv>
			<ImagesDiv className='fadeIn' style={{
				animationDuration: "4s",
				animationDelay: "0.5s"
			}} ref={ref} />
			<CopyHolder {...props} />
		</ContainerDiv>
	);
}