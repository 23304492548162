import { useMemo } from "react";
import styled from "styled-components";

const ErrorDiv = styled.div`
    position: absolute;
    z-index: 1000;
    height: 70px;

    background: url("images/contact/Info_pop_up.svg");
    background-position: center;
    background-size: cover;
    background-overflow: visible;
    background-repeat: no-repeat;

    opacity: 0;

	@media only screen and (max-width: 768px) {
    	height: 65px;
    }

    @media only screen and (max-width: 500px) {
    	height: 45px;
    }
`;

const ErrorTextDiv = styled.div`
	position: relative;
	top: 10px;
    height: 60px;

	font-family: 'Jost';
	font-size: 16px;
	color: black;

	display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 768px) {
    	height: 56px;
        top: 9px;
        font-size: 12px;
    }

    @media only screen and (max-width: 500px) {
        height: 56px;
        top: 7px;
    	height: 38px;
    }
`;

interface Properties {
    width: string;
    top: string;
    left: string;

    error: string;
}

function ErrorPopUp(props: Properties) {
    const hasError = useMemo(() => {
        return props.error !== "";
    }, [props.error])

    const style = useMemo(() => {
        return {
            opacity: hasError ? "1" : "0",
            width: hasError ? props.width : "0",
            top: props.top,
            left: props.left,
        };
    }, [hasError, props.width, props.top, props.left]);

    const className = useMemo(() => {
        return hasError ? "fadeOut" : "";
    }, [hasError]);

    return (
        <ErrorDiv style={style} className={className}>
            <ErrorTextDiv>
                {props.error}
            </ErrorTextDiv>
        </ErrorDiv>
    );
}

export default ErrorPopUp;