import React, { useCallback } from 'react';
import styled from 'styled-components';
import { SectionInfo } from './MenuProperties';

const UnderlineDiv = styled.div`
    height: 1px;
    width: 60%;
    background-color: white;
    margin: 0 auto;

    border-radius: 5px;
    opacity: 0.2;

    transition: height 0.5s;

    @media only screen and (min-width: 768px) {
        display: none;
    }
`;

const TextDiv = styled.div`
    font-family: 'Jost';
    font-weight: 100;
    font-size: 24px;
    color: white;

    transition: color 0.5s, height 0.5s, font-size 0.5s;
`;

const ContainerDiv = styled.div`
    transition: width 0.5s;

    @media only screen and (min-width: 768px) {
        :hover {
            cursor: pointer;

            ${TextDiv} {
                color: #12EFD4;
            }
        }
    }
`;

type Properties = {
    disabled: boolean;
} & SectionInfo;

export default function MenuButton(props: Properties) {
    const scroll = useCallback(() => {
        if (!props.disabled) {
            props.scrollTo();
        }
    }, [props.scrollTo, props.disabled]);

    return (
        <ContainerDiv onClick={scroll} style={{
            width: props.disabled ? "16px" : "150px"
        }} >
            <TextDiv style={{
                fontSize: props.disabled ? "0px" : "20px"
            }}>
                {props.name}
            </TextDiv>
            <UnderlineDiv style={{
                height: props.disabled ? "8px" : "1px"
            }} />
        </ContainerDiv>
    );
}
