import styled from "styled-components";
import { AnimationOnScroll } from "../Helpers/AnimationScrollContainer";
import { animationProps } from "../Helpers/defaultScrollAnimationProps";

interface Link {
    text: string;
    url: string;
}

interface Properties {
    title: string;
    content: (string | Link)[];
    pairity: 'even' | 'odd';
    imageSrc?: string;
}

const ContainerDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    padding-left: 72px;
    padding-right: 72px;
    @media only screen and (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    gap: 65px;

    @media only screen and (max-width: 768px) {
        width: auto;
        gap: 30px;
    }
`;

const TextContainer = styled.div`
    // height: 100%;
    flex: 1;
`;

const Title = styled.div`
    font-family: 'Jost';
    color: white;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
`;

const Text = styled.div`
    font-family: 'Jost';
    text-align: left;
    color: white;
    font-size: 18px;
`;

const LinkText = styled.a`
    font-family: 'Jost';
    text-align: left;
    color: #D9F1F3;
    font-size: 18px;

    :hover {
        color: lightblue;
        cursor: pointer;
    }
`;

const ImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        flex: 0;
    }
`;

const Image = styled.img`
    max-width: 100px;
`;

const imageAnimationProps = {
    animatePreScroll: true,
    initiallyVisible: false,
    animateOnce: false,
    duration: 0.8,
    offset: 200,
    delay: 0,
};

export default function WorkItemText(props: Properties) {
    return (
        <AnimationOnScroll {...animationProps} >
            <ContainerDiv style={{
                backgroundColor: props.pairity === 'even' ? "#071524" : "#051322",
            }}>
                {props.pairity === 'odd' &&
                    <ImageContainer>
                        <AnimationOnScroll {...imageAnimationProps}
                            animateIn={'flyInLeft'}
                            animateOut={'fadeOutLeft'}
                        >
                            <Image src={props.imageSrc ?? 'images/team/Avatar_Circle_fill.svg'} />
                        </AnimationOnScroll>
                    </ImageContainer>
                }
                <TextContainer>
                    <Title>{props.title}</Title>
                    <Text>
                        {props.content.map((part, index) => {
                            if (typeof part === 'string' || part instanceof String) {
                                return (part as string);
                            } else {
                                return <LinkText
                                    key={index}
                                    href={(part as Link).url}
                                >{(part as Link).text}</LinkText>;
                            }
                        })}
                    </Text>
                </TextContainer>
                {props.pairity === 'even' &&
                    <ImageContainer>
                        <AnimationOnScroll {...imageAnimationProps}
                            animateIn={'flyInRight'}
                            animateOut={'fadeOutRight'}
                        >
                            <Image src={props.imageSrc ?? 'images/team/Avatar_Circle_fill.svg'} />
                        </AnimationOnScroll>
                    </ImageContainer>
                }
            </ContainerDiv>
        </AnimationOnScroll>
    );
}
