import React from 'react';
import styled from 'styled-components';

const ConstainerDiv = styled.div`
    font-family: 'Jost';
	font-weight: 300;
	font-size: 26px;
    font-weight: bold;
	color: white;

    margin-top: 25px;
    margin-bottom: 45px;
`;

export default function Title(props: { title: string }) {
    return (
        <ConstainerDiv className='Title'>{ props.title }</ConstainerDiv>
    );
}
