import { useRef, useState, useCallback, useMemo, useEffect } from "react";
import styled from "styled-components";
import useWindowSize from "../hooks/useWindowSize";
import ContactSection from "./Contact/ContactSection";
import { AnimationOnScroll } from "./Helpers/AnimationScrollContainer";
import CookiesBar from "./Helpers/CookiesBar";
import { animationProps } from "./Helpers/defaultScrollAnimationProps";
import Footer from "./Helpers/Footer";
import LandingImage from "./LandingImage/LandingImage";
import MenuBar from "./Menu/MenuBar";
import PartnersSection from "./Partners/PartnersSection";
import TeamSection from "./Team/TeamSection";
import WorkSection from "./Work/WorkSection";
import Cookies from "universal-cookie";

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    opacity: 0;
    background-color: blue;
`;

export default function Home() {
	const rootRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null);
	const workRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null);
	const teamRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null);
	const contactRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null);

	const scroll = (ref: React.MutableRefObject<null | HTMLDivElement>) => {
		const offset = ref.current!.offsetTop;
		window.scrollTo({
			top: (offset - 110)
		});
	};

	const [expanded, setExpanded] = useState(false);
	const [width, height] = useWindowSize();

	const scrollToContact = useCallback(() => {
		scroll(contactRef);
	}, [contactRef]);

	const colapsable = useMemo(() => {
		return width < 756;
	}, [width]);

	const menuProps = useMemo(() => {
		return {
			sections: [
				{
					name: "Work",
					scrollTo: () => { scroll(workRef); },
				},
				{
					name: "Team",
					scrollTo: () => { scroll(teamRef); },
				},
				{
					name: "Contact",
					scrollTo: () => { scroll(contactRef); },
				}
			],
			colapsable: colapsable,
			expanded: !colapsable || expanded,
			toggleExpand: () => {
				if (colapsable) {
					setExpanded(!expanded);
				}
			}
		}
	}, [workRef, teamRef, contactRef, expanded, width]);

	const updateExpanded = () => {
		setExpanded(false);
	}

	useEffect(() => {
		window.addEventListener('scroll', updateExpanded);
		return () => {
			window.removeEventListener('scroll', updateExpanded);
		};
	}, []);

	return (
		<div ref={rootRef} className='App'>
			<CookiesBar />
			<MenuBar {...menuProps} />
			<Overlay style={{
				display: colapsable && expanded ? "block" : "none"
			}} onClick={() => {
				setExpanded(!expanded);
			}} />
			<LandingImage scrollToContact={scrollToContact} />
			<div ref={workRef} className='Section'>
				<AnimationOnScroll {...animationProps} >
					<WorkSection />
				</AnimationOnScroll>
			</div>
			<div ref={teamRef} className='Section'>
				<AnimationOnScroll {...animationProps} >
					<TeamSection />
				</AnimationOnScroll>
			</div>
			<div className='Section'>
				<AnimationOnScroll {...animationProps} >
					<PartnersSection />
				</AnimationOnScroll>
			</div>
			<div ref={contactRef} className='Section'>
				<AnimationOnScroll {...animationProps} >
					<ContactSection />
				</AnimationOnScroll>
			</div>
			<Footer />
		</div>
	);
}