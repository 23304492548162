import React, { useCallback } from 'react';
import styled from 'styled-components';
import { AnimationOnScroll } from '../Helpers/AnimationScrollContainer';
import { animationProps } from '../Helpers/defaultScrollAnimationProps';

interface Properties {
    imageSrc: string;
    name: string;
    role: string;
    telegramHandle: string;
    index: number;
    decorationPosition?: 'start' | 'end';
};

const imageWidth = 190;

const ContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const NameDiv = styled.div`
    font-family: 'Jost';
    color: white;
    font-size: 22px;
    padding-top: 18px;
`;
    
const RoleDiv = styled.div`
    font-family: 'Jost';
    color: white;
    font-size: 22px;
    padding-bottom: 10px;
    font-weight: bold;
`;

const Decoration = styled.div`
    position: absolute;

    background: url('images/decorations/cross_deco.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 30px;
    height: 30px;

    @media only screen and (max-width: 768px) {
        width: 20px;
        height: 20px;
    }
`;

const FaceImage = styled.img`
    width: ${imageWidth * 1.1}px;

    @media only screen and (max-width: 768px) {
        width: ${imageWidth * 1.1 * 0.5}px;
    }
`;

const TelegramIcon = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: opacity 0.35s;

    background: url('images/icons/telegram.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;

const CircleDiv = styled.div`
    position: relative;

    width: ${imageWidth}px;
    height: ${imageWidth}px;

    transition: background 0.35s;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background: url("images/team/Avatar_Circle.svg");

    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        background: url("images/team/Avatar_Circle_fill.svg");
        cursor: pointer;

        ${TelegramIcon} {
            opacity: 0.5;
        }
    }

    @media only screen and (max-width: 768px) {
        width: ${imageWidth * 0.5}px;
        height: ${imageWidth * 0.5}px;
    }
`;

export default function TeamMember(props: Properties) {
    const handleClick = useCallback(() => {
        window.open(`https://t.me/${props.telegramHandle}`, '_blank');
    }, [props.telegramHandle]);

	return (
        <AnimationOnScroll {...animationProps} style={{
            animationDelay: `${0.2 * props.index}s`
        }}>
            <ContainerDiv>
                <CircleDiv onClick={handleClick} style={{backgroundSize: "contain"}}>
                    {props.decorationPosition && <Decoration style={
                        props.decorationPosition === 'start' ? {
                            top: "-20px",
                            left: "-20px"
                        } : {
                            bottom: "-20px",
                            right: "-20px"
                        }} />
                    }
                    <FaceImage className='faceimage' src={props.imageSrc} />
                    <TelegramIcon />
                </CircleDiv>
                <NameDiv>{props.name}</NameDiv>
                <RoleDiv>{props.role}</RoleDiv>
            </ContainerDiv>
        </AnimationOnScroll>
	);

}