import { useCallback, useState } from 'react';
import Cookies from 'universal-cookie';
import styled from 'styled-components';

const ContainerDiv = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1000;

    background-color: #12EFD4;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 10px;
    padding-left: 60px;
    padding-right: 60px;

    @media only screen and (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const Text = styled.div`
    flex: 1;

    font-family: 'Jost';
    font-size: 20px;
    color: black;

    text-align: left;
    align-items: center;

    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
`;

const Button = styled.div`
    font-family: 'Jost';
    font-size: 18px;
    color: black;

    height: 50px;
    width: 60px;

    border-color: #5A738D;
	border-style: solid;
	border-width: 1px;
	box-sizing: border-box;

    margin-left: 15px;

    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    :hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        margin-left: 10px;
        font-size: 16px;
    }
`;

export default function CookiesBar() {

    const getCookieConcentCookie = () => {
        const cookies = new Cookies();
        const cookieConcentCookie = cookies.get("cookieConcentCookie");
        return !!cookieConcentCookie;
    }

    const [cookieConcentExists, setCookieConcentExists] = useState(getCookieConcentCookie());

    const handleOkClicked = useCallback(() => {
        const cookies = new Cookies();
        cookies.set('cookieConcentCookie', 'OK');
        setCookieConcentExists(getCookieConcentCookie());
    }, []);

    return (
        <ContainerDiv style={{
            display: cookieConcentExists ? "none" : "auto"
        }}>
            <Text>By using this website you agree to the use of cookies. We use cookies to provide you with the best experience and to help our website run effectively.</Text>
            <Button onClick={handleOkClicked}>OK</Button>

        </ContainerDiv>
    );
}
