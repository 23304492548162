import React, { useMemo } from 'react';
import styled from 'styled-components';
import ErrorPopUp from '../../Helpers/ErrorPopUp';

interface Properties {
	placeholder: string;
	text: string;
	onChange: (newText: string) => void;
	error: string;
}

const ContainerDiv = styled.div`
	position: relative;
	width: 50%;
	height: 35px;
`;

const Input = styled.input`
	position: absolute;
	top: 0;
	left: 0;

	box-sizing:border-box;

	width: 100%;
	max-width: 100%;
	height: 100%;
	padding-left: 10px;

	font-family: 'Jost';
	font-size: 20px;
	color: white;

	background-color: #1A2939;
	border-color: #5A738D;
	border-style: solid;
	border-width: 1px;

	::placeholder {
		color: #3F4B59;
	}
`;

export default function TextField(props: Properties) {
	return (
		<ContainerDiv>
			<Input type="text" placeholder={props.placeholder} onChange={(event) => { props.onChange(event.target.value) }} value={props.text} />
			<ErrorPopUp width='104%' top='80%' left='-2%' error={props.error} />
		</ContainerDiv>
	);
}
