import React from 'react';
import styled from 'styled-components';
import Button from '../Helpers/Button';

const BIG_TEXT_SIZE = 80;
const BIG_TEXT_SIZE_SMALL_SCREEN = 55;

const SMALL_TEXT_SIZE = 20;
const SMALL_TEXT_SIZE_SMALL_SCREEN = 0;

const CLICKABLE_TEXT_SIZE = 95;
const CLICKABLE_TEXT_SIZE_SMALL_SCREEN = 65;


const ContainerDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-left: 225px;
	padding-top: 80px;

	flex: 1;

	@media only screen and (max-width: 1100px) {
		padding-left: 120px;
		justify-content: center;
		align-items: center;
	}

	@media only screen and (max-width: 768px) {
		padding-left: 20px;

		align-items: center;
		justify-content: center;
	}
`;

const ClickableText = styled.div`
    width: 500px;
	height: ${CLICKABLE_TEXT_SIZE}px;

    background: url("images/decorations/DeFiChain_Outlined.svg");
	background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    transition: background 0.5s ease;

    :hover {
        background: url("images/decorations/DeFiChain_Filled.svg");
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;

		cursor: pointer;
    }

	@media only screen and (max-width: 768px) {
		width: 300px;
		height: ${CLICKABLE_TEXT_SIZE_SMALL_SCREEN}px;
	}
`;

const ClickableTextContainer = styled.div`
	opacity: 0;

	padding-left: 90px;

	@media only screen and (max-width: 768px) {
		padding-left: 0px;
	}
`;

const SmallText = styled.div`
	font-family: 'Jost';
	font-weight: 300;
	font-size: ${SMALL_TEXT_SIZE}px;
	color: white;
	text-align: left;

	opacity: 0;

	@media only screen and (max-width: 768px) {
		font-size: ${SMALL_TEXT_SIZE_SMALL_SCREEN}px;
	}
`;

const TopBigText = styled.div`
	font-family: 'Jost';
	font-weight: 900;
	font-size: ${BIG_TEXT_SIZE}px;
	color: white;
	text-align: left;

	@media only screen and (max-width: 768px) {
		font-size: ${BIG_TEXT_SIZE_SMALL_SCREEN}px;
	}
`;

const BottomBigText = styled.div`
	font-family: 'Jost';
	font-weight: 600;
	font-size: ${BIG_TEXT_SIZE}px;
	color: white;
	text-align: left;

	opacity: 0;

	@media only screen and (max-width: 768px) {
		font-size: ${BIG_TEXT_SIZE_SMALL_SCREEN}px;
		text-align: center;
	}
`;

const BottomTextContainer = styled.div`
	padding-left: 55px;
	@media only screen and (max-width: 768px) {
		margin-top: 10px;
		padding-left: 0px;
		line-height: 65px;
	}
`;

const ButtonContainer = styled.div`
	padding-left: 150px;
	padding-top: 30px;
	opacity: 0;

	@media only screen and (max-width: 768px) {
		width: 100%;
		padding-left: 0px;
		display: fex;
		flex-direction: row;
		justify-content: center;
	}
`;

interface Properties {
	scrollToContact: VoidFunction;
}

export default function CopyHolder(props: Properties) {
	return (
		<ContainerDiv>
			<TopBigText className='flyInLeft' style={{
				animationDuration: "4s",
			}}>Accelerating</TopBigText>
			<ClickableTextContainer className='flyInRight' style={{
				animationDuration: "4s",
				animationDelay: "0.4s"
			}}>
				<ClickableText onClick={() => {
					window.open("https://defichain.com", "_blank");
				}} />
			</ClickableTextContainer>
			<BottomTextContainer>
				<BottomBigText className='flyInLeft' style={{
					animationDuration: "4s",
					animationDelay: "0.8s"
				}}>The Future of Finance.</BottomBigText>
				<SmallText className='fadeIn' style={{
					animationDelay: "1.0s"
				}}>Accelerating DeFiChain's adoption as the go-to Decentralized Finance Blockchain.</SmallText>
			</BottomTextContainer>
			<ButtonContainer className='fadeIn' style={{
				animationDelay: "1.2s"
			}}>
				<Button visible={true} text={'Get in touch with us'} onClick={props.scrollToContact} />
			</ButtonContainer>
		</ContainerDiv>
	);

}