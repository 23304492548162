import styled from "styled-components";
import Footer from "./Helpers/Footer";
import MenuBar from "./Menu/MenuBar";

const Container = styled.div`
    background-color: #071524;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;
`;

const MainContentContainer = styled.div`
    padding-top: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Title = styled.div`
    font-family: 'Jost';
	font-weight: 300;
	font-size: 26px;
    font-weight: bold;
	color: white;

    margin-top: 25px;
    margin-bottom: 45px;

    text-align: center;
`;

const SubTitle = styled.div`
    font-family: 'Jost';
	font-weight: 600;
	font-size: 22px;
	color: white;

    margin-top: 25px;
    margin-bottom: 45px;
`;

const ContentContainer = styled.div`
    max-width: 90%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
`;

const TextSubContainer = styled.div`
    width: 45%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
`;

const Text = styled.div`
    font-family: 'Jost';
	font-weight: 300;
	font-size: 20px;
	color: white;

    margin-top: 25px;
    margin-bottom: 45px;

    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
`;

export default function Imprint() {
    return (
        <Container>
            <MenuBar sections={[]} colapsable={false} expanded={false} toggleExpand={() => { }} />
            <MainContentContainer>
                <Title>
                    Imprint
                </Title>
                <ContentContainer>
                    <TextSubContainer>
                        <SubTitle>Information according to § 5 TMG:</SubTitle>
                        <Text>
                            DeFiChain Accelerator LTD
                            <br />
                            26 Sotiri Papalazarou, Apartment 201
                            <br />
                            8201, Geroskipou, Paphos
                            <br />
                            Cyprus
                        </Text>
                    </TextSubContainer>
                    <TextSubContainer>
                        <SubTitle>Responsible for the content
                            according to § 55 para. 2 RstV:</SubTitle>
                        <Text>
                            Santiago Sabater
                            <br />
                            26 Sotiri Papalazarou, Apartment 201
                            <br />
                            8201, Geroskipou, Paphos
                            <br />
                            Cyprus
                        </Text>
                    </TextSubContainer>
                </ContentContainer>
            </MainContentContainer>
        </Container>
    );
}
