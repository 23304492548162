import React, { useCallback } from 'react';
import styled from 'styled-components';

interface Props {
	text: string;
	link: string;
}

const ContainerDiv = styled.div`
	display: flex;
	justify-items: center;
	align-items: center;

	border-width: 1px;
    border-radius: 2px;
    border-color: #5A738D;
    border-style: solid;

	margin: 5px;

	:hover {
		cursor: pointer;
		background: rgba(255, 255, 255, 0.1);
	}
`;

const TelegramIcon = styled.img`
	margin-left: 5px;
`;

const TextWrapper = styled.div`
	width: calc(100% - 45px);
	padding: 4px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	justify-content: center;
`;

const TextDiv = styled.div`
	font-family: 'Jost';
	font-weight: 300;
	font-size: 19px;
	color: white;
`;

export default function TelegramLinkBar(props: Props) {
	const goToLink = useCallback(() => {
		window.open(props.link, '_blank')?.focus();
	}, [props.link])
	return (
		<ContainerDiv onClick={goToLink}>
			<TelegramIcon width={45} src='/images/icons/telegram.svg' />
			<TextWrapper>
				<TextDiv>
					{props.text}
				</TextDiv>
			</TextWrapper>
		</ContainerDiv>
	);
}