import React from 'react';
import styled from 'styled-components';
import { AnimationOnScroll } from '../Helpers/AnimationScrollContainer';
import { animationProps } from '../Helpers/defaultScrollAnimationProps';
import ContactForm from './Form/ContactForm';
import LinksHolder from './Links/LinksHolder';

const ContainerDiv = styled.div`
    display: flex;
    position: relative;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding-left: 20px;
        padding-right: 20px;
    }

    padding-bottom: 100px;
    margin-top: 10px;
`;

const Line = styled.div`
    background: url('images/decorations/divider_vertical.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    margin-top: 128px;
    padding-left: 1px;
    padding-right: 1px;

    height: 305px;

    @media only screen and (max-width: 768px) {
        background: url('images/decorations/divider_horizontal.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;

        width: 80%;
        height: auto;
    }
`;

export default function ContactSection() {
    return (
        <ContainerDiv>
            <ContactForm />
            <Line />
            <LinksHolder />
        </ContainerDiv>
    );
}
