import React from 'react';
import styled from 'styled-components';

const ContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background: url('images/decorations/button_deco.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;

const StyledButton = styled.button`
    border-radius: 1px;
    border-width: 0;

    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 30px;
    min-width: 150px;

    font-family: 'Jost';
    font-size: 17px;
    font-weight: 800;
    color: black;
    background-color: white;

    transition: background-color 0.5s;

    :hover {
        cursor: pointer;
        background-color: #D9F1F3;
    }
`;

const DisabledButton = styled.button`
    border-radius: 1px;
    border-width: 0;

    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 30px;
    min-width: 150px;

    font-family: 'Jost';
    font-size: 17px;
    font-weight: 800;
    color: black;
    background-color: grey;
`;

interface Properties {
    text: string;
    onClick: VoidFunction;
    disabled?: boolean;
    visible?: boolean;
}

export default function Button(props: Properties) {
    return (
        <div>

            {props.visible &&
                <ContainerDiv>
                    {!props.disabled && <StyledButton onClick={props.onClick}>{props.text}</StyledButton>}
                    {props.disabled && <DisabledButton>{props.text}</DisabledButton>}
                </ContainerDiv>
            }
        </div>
    );
}
