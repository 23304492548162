import React from 'react';
import styled from 'styled-components';
import { AnimationOnScroll } from '../Helpers/AnimationScrollContainer';
import { animationProps } from '../Helpers/defaultScrollAnimationProps';

const Title = styled.div`
    font-family: 'Jost';
    color: white;
    font-weight: bold;
    font-size: 26px;
`;

const ContainerDiv = styled.div`
    padding-bottom: 20px;
`;

const IconsContainer = styled.div`
    width: 75%;
    margin: 0 auto;
    padding-bottom: 60px;
`;

const IconsFlexboxMain = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    padding-bottom: 0px;

    @media only screen and (min-width: 768px) {
        justify-content: space-between;
    }
`;

const Icon = styled.img`
    padding-top: 20px;

    height: 40px;

    @media only screen and (max-width: 768px) {
        height: 30px;
    }
`;

const HorizontalLine = styled.div`
    padding-top: 60px;
    padding-bottom: 60px;
    width: 78%;
    height: 10px;
    margin: 0 auto;

    background: url('images/decorations/divider_horizontal.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    // background-color: white;
`;

export default function PartnersSection() {
    return (
        <ContainerDiv>
            <Title>{"Partners"}</Title>
            <HorizontalLine />
            <IconsContainer>
                <IconsFlexboxMain>
                    <AnimationOnScroll {...animationProps} >
                        <Icon src='/images/partners/defichain.svg' />
                    </AnimationOnScroll>
                    <AnimationOnScroll {...animationProps} >
                        <Icon src='/images/partners/birthday_research.png' />
                    </AnimationOnScroll>
                    <AnimationOnScroll {...animationProps} >
                        <Icon src='/images/partners/marketaccross.svg' />
                    </AnimationOnScroll>
                    <AnimationOnScroll {...animationProps} >
                        <Icon src='/images/partners/dfx.svg' />
                    </AnimationOnScroll>
                    <AnimationOnScroll {...animationProps} >
                        <Icon src='/images/partners/coinmarketcap.png' />
                    </AnimationOnScroll>
                </IconsFlexboxMain>
            </IconsContainer>
        </ContainerDiv>
    );
}
