import styled from "styled-components";
import Footer from "./Helpers/Footer";
import MenuBar from "./Menu/MenuBar";

import privacyPolicyText from '../docu/privacyPolicyText';

const Container = styled.div`
    background-color: #071524;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    width: 100vw;
    max-width:100%;
    padding-top: 250px;
`;

const Title = styled.div`
    font-family: 'Jost';
	font-weight: 300;
	font-size: 26px;
    font-weight: bold;
	color: white;

    margin-top: 25px;

    text-align: center;
    padding-bottom: 25px;
`;

const ContentContainer = styled.div`
    max-width: 90%;
    width: 900px;
`;

const Text = styled.div`
    font-family: 'Jost';
	font-weight: 300;
	font-size: 20px;
	color: white;

    padding-top: 25px;

    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
`;

export default function Privacy() {
    return (
        <Container>
            <MenuBar sections={[]} colapsable={false} expanded={false} toggleExpand={() => { }} />\
            <ContentContainer>
                <Title>
                    Privacy Policy
                </Title>
                <Text>
                    {privacyPolicyText.split("\n\n").map((text) => <p>{text}</p>)}
                </Text>
            </ContentContainer>
        </Container>
    );
}