import React, { useMemo } from 'react';
import styled from 'styled-components';
import ErrorPopUp from '../../Helpers/ErrorPopUp';

interface Properties {
	placeholder: string;
	text: string;
	width: number;
	onChange: (newText: string) => void;
	error: string;
}

const ContainerDiv = styled.div`
	position: relative;
	margin-top: 15px;
	padding: 0px;
	max-width: 100%;
	height: 250px;
`;

const Area = styled.textarea`
	position: relative;

	width: 100%;
	height: 100%;
	max-width: 100%;

	resize: none;

	font-family: 'Jost';
	font-size: 20px;
	color: white;
	padding-left: 10px;

	background-color: #1A2939;
	border-color: #5A738D;
	border-style: solid;
	border-width: 0.1mm;
	box-sizing: border-box;

	::placeholder {
		color: #3F4B59;
	}
`;

export default function TextArea(props: Properties) {
	return (
		<ContainerDiv>
			<Area style={{
				width: `${props.width}px`
			}} placeholder={props.placeholder} onChange={(event) => { props.onChange(event.target.value) }} value={props.text} />
			<ErrorPopUp width='50%' top='90%' left='25%' error={props.error} />
		</ContainerDiv>
	);
}
