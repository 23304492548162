import React, { useCallback, useMemo, useRef, useState } from 'react';
import TextArea from './TextArea';
import TextField from './TextField';
import Title from '../Title';
import Checkbox from '../../Helpers/Checkbox';
import styled from 'styled-components';
import Button from '../../Helpers/Button';
import { send } from '@emailjs/browser';
import validator from 'validator';

import GoogleReCaptchaV2 from "react-google-recaptcha";
import axios from 'axios';

const width = 600;

const TextFieldsContainer = styled.div`
	display: flex;
	width: ${width}px;
    max-width: 100%;
	justify-content: space-between;
    gap: 15px;
`;

const ContainerDiv = styled.div`
    position: relative;

	width: 50%;
    padding: 20px;

	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

    @media only screen and (max-width: 768px) {
        width: 90%;
    }
`;

const BreakDiv = styled.div`
    width: 100%;
    height: 30px;
    opacity: 0;
`;

const FeedbackDiv = styled.div`
    position: absolute;
    top: calc(50% - 23px);
    left: 25%;
    z-index: 1000;

    width: 50%;
    height: 46px;

    background: #12EFD4;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Jost';
	font-size: 20px;
	color: black;

    opacity: 0;
`;

const successMessage = "Successfully sent!";

export default function ContactForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [suscribeToNewsletter, setSuscribeToNewsletter] = useState(false);

    const nameOk = useMemo(() => {
        return name.length > 0;
    }, [name]);
    const [nameError, setNameError] = useState("");
    const emailOk = useMemo(() => {
        return validator.isEmail(email);
    }, [email]);
    const [emailError, setEmailError] = useState("");
    const messageOk = useMemo(() => {
        return message.length > 0;
    }, [message]);
    const [messageError, setMessageError] = useState("");

    const [success, setSuccess] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const recaptchaRef: React.MutableRefObject<GoogleReCaptchaV2 | null> = useRef(null);
    // const [failedSubmitCounter, setFailedSubmitCounter] = useState(0);
    let failedSubmitCounter = 0;
    const setFailedSubmitCounter = (cnt: number) => { failedSubmitCounter = cnt; }


    const submit = useCallback(async () => {
        let dataValid = true;
        if (!emailOk) {
            setEmailError("Email should be in format: yourname@example.com");
            dataValid = false;
        }
        if (!nameOk) {
            setNameError("Who is there?");
            dataValid = false;
        }
        if (!messageOk) {
            setMessageError("How about writing us something?");
            dataValid = false;
        }

        setSubmitDisabled(true);
        setTimeout(() => {
            if (dataValid) {
                setName("");
                setEmail("");
                setMessage("");

                setFailedSubmitCounter(0);
                recaptchaRef.current!.reset();
                setVerified(false);
            }

            setSuccess(false);
            setSubmitDisabled(false);
            setNameError("");
            setEmailError("");
            setMessageError("");
        }, 2000);

        if (!dataValid) {
            setFailedSubmitCounter(failedSubmitCounter + 1);
            if (failedSubmitCounter >= 3) {
                setFailedSubmitCounter(0);
                recaptchaRef.current!.reset();
                setVerified(false);
            }
            return;
        }
        send("service_loqykdo", "template_1rx7tty", {
            name: name,
            email: email,
            message: message,
        }, "9dJPpgeU0HgZ9ADpk");
        setSuccess(true);
    }, [name, email, message]);

    const [verified, setVerified] = useState(false);
    const handleVerify = useCallback((token: string | null) => {
        setVerified(token != null);
    }, []);

    return (
        <ContainerDiv>
            <Title title="Let's get in touch" />
            <TextFieldsContainer>
                <TextField error={nameError} placeholder='Name' text={name} onChange={(text) => setName(text)} />
                <TextField error={emailError} placeholder='Email' text={email} onChange={(text) => setEmail(text)} />
            </TextFieldsContainer>
            <TextArea error={messageError} width={width} placeholder='Dear accelerators,' text={message} onChange={(text) => setMessage(text)} />
            {/* <Checkbox onChange={(newValue) => {
                    setSuscribeToNewsletter(newValue);
                }} label={"Yes, I'd like to sign up to get update about DeFiChain"} /> */}
            <BreakDiv />
            <Button visible={verified} disabled={submitDisabled} text="Submit" onClick={submit} />
            <div>
                <GoogleReCaptchaV2
                    ref={recaptchaRef}
                    theme='dark'
                    size='compact'
                    hidden={verified}
                    onChange={handleVerify}
                    sitekey='6LfpQfshAAAAAJ1vIGoQS4OZ_VsXP-AOWVDR-Gyw'
                />
            </div>
            <FeedbackDiv
                className={success ? "fadeOut" : ""}
                style={{
                    opacity: success ? "1" : "0",
                    height: success ? "45px" : "0px"
                }} >{successMessage}</FeedbackDiv>
        </ContainerDiv>
    );
}
