import './App.css';
import "animate.css/animate.min.css";
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Imprint from './components/Imprint';
import Privacy from './components/Privacy';

function App() {
	return (
		<Routes>
			<Route path='/' element={<Home />}></Route>
			<Route path='/imprint' element={<Imprint />}></Route>
			<Route path='/privacy' element={<Privacy />}></Route>
		</Routes>
	);
}

export default App;
