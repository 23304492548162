import { hasSelectionSupport } from '@testing-library/user-event/dist/utils';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import MenuButton from './MenuButton';
import MenuProperties from './MenuProperties';

const ContainerDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100px;
    transition: flex 0.5s;
`;

export default function Menu(props: MenuProperties) {

    const onClick = useCallback(() => {
        if (!props.expanded) {
            props.toggleExpand();
        }
    }, [props.toggleExpand]);

    const menuButtons = useMemo(() => {
        return props.sections.map((section, index) => {
            const buttonProps = {
                ...section,
                scrollTo: () => {
                    props.toggleExpand();
                    section.scrollTo();
                }
            };
            return <MenuButton key={index} disabled={!props.expanded} {...buttonProps} />
        })
    }, [props.toggleExpand, props.sections, props.expanded]);

    return (
        <ContainerDiv style={{
            flex: props.expanded ? "1" : "0"
        }} onClick={onClick}>
            {
                menuButtons
            }
        </ContainerDiv>
    );
}
