import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isPropertyAccessExpression } from 'typescript';
import Menu from './Menu';
import MenuProperties from './MenuProperties';

const height = 75;

const padding = 20;
const margin = 45;

const paddingSmallScreen = 17;
const marginSmallScreen = 13;

const backgroundColor = "rgba(63, 75, 89, 0.1)";
const transparentColor = "rgba(63, 75, 89, 0.0)";

const ContainerDiv = styled.div`
    position: absolute;
    top: 40px;
    width: 100%;
    height: ${height}px;

    z-index: 100;

    &.Sticky {
        position: fixed;
        top: 10px;
    }
`;

const Bar = styled.div`
    width: calc(100% - ${2 * (margin + padding)}px);

    height: 100%;
    margin: 0 auto;

    padding-left: ${padding}px;
    padding-right: ${padding}px;
    margin-left: ${margin}px;
    margin-right: ${margin}px;

    border-radius: ${height / 2}px ${height / 2}px;
    backdrop-filter: blur(10px);

    display: flex;
    justify-content: space-between;
    gap: calc((100% - 600px) / 3);

    @media only screen and (max-width: 768px) {
        width: calc(100% - ${2 * (marginSmallScreen + paddingSmallScreen)}px);

        padding-left: ${paddingSmallScreen}px;
        padding-right: ${paddingSmallScreen}px;
        margin-left: ${marginSmallScreen}px;
        margin-right: ${marginSmallScreen}px;
    }
`;

const Logo = styled.div`
    width: 150px;

    mask: url('images/icons/defichain_accelerator_logo.svg');
    background-color: white;
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;

    transition: background-color 0.5s ease, width 0.5s ease;

    fill: #12EFD4;
    :hover {
        background-color: #12EFD4;
        cursor: pointer;
    }
`;

export default function MenuBar(props: MenuProperties) {
    const containerRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null);
    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', updateStickiness);
        return () => {
            window.removeEventListener('scroll', updateStickiness);
        };
    }, [containerRef]);

    const updateStickiness = () => {
        const scrollTop = window.scrollY;
        setSticky(scrollTop >= 30);
    }

    const onLogoClicked = () => {
        const pathname = window.location.pathname;
        if (pathname === '/') {
            window.scrollTo({ top: 0 });
        } else {
            window.open('/', '_self');
        }
    }

    return (
        <ContainerDiv ref={containerRef} className={isSticky ? "Sticky" : ""} style={{
            backgroundColor: props.colapsable && props.expanded ? backgroundColor : transparentColor,
            top: props.colapsable && props.expanded ? "0" : undefined,
            height: props.colapsable && props.expanded ? `${height + 10}px` : `${height}px`,
            backdropFilter: props.colapsable && props.expanded ? "blur(10px)" : undefined,
        }}>
            <Bar style={{
                gap: props.colapsable && props.expanded ? "0px" : "calc((100% - 600px) / 3)",
                backgroundColor: props.colapsable && props.expanded ? transparentColor : backgroundColor,
                backdropFilter: props.colapsable && props.expanded ? "none" : "blur(10px)",
            }}>
                <Logo style={{
                    width: props.colapsable && props.expanded ? "0px" : "150px",
                }} onClick={onLogoClicked} />
                <Menu {...props} />
            </Bar>
        </ContainerDiv>
    );
}
