import React from 'react';
import styled from 'styled-components';
import TeamMember from './TeamMember';

const ContainerDiv = styled.div`
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
`;

const Title = styled.div`
    font-family: 'Jost';
    color: white;
    font-weight: bold;
    font-size: 26px;
    padding-bottom: 100px;
`;

const TeamMemberContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
`;

export default function TeamSection() {
    return (
        <ContainerDiv>
            <Title>{"Team"}</Title>
            <TeamMemberContainer>
                <TeamMemberContainer>
                    <TeamMember index={0} imageSrc='images/team/santiago.png' name='Santiago' role='Blockchain' telegramHandle='Santiago_Sabater' decorationPosition='start' />
                    <TeamMember index={1} imageSrc='images/team/ben.png' name='Ben' role='Marketing' telegramHandle='bentheman88' />
                </TeamMemberContainer>
                <TeamMemberContainer>
                    <TeamMember index={2} imageSrc='images/team/lazar.png' name='Lazar' role='Creative' telegramHandle='ImLazar' />
                    <TeamMember index={3} imageSrc='images/team/jovan.png' name='Jovan' role='Technical' telegramHandle='basically_jovan'  decorationPosition='end' />
                </TeamMemberContainer>
            </TeamMemberContainer>
        </ContainerDiv>
    );
}
