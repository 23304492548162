import styled from 'styled-components';

const ContainerDiv = styled.div`
    min-height: 40px;
    background-color: #12EFD4;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 10px;
    padding-left: 60px;
    padding-right: 60px;

    @media only screen and (max-width: 768px) {
        min-height: 60px;
        padding-left: 15px;
        padding-right: 15px;
        flex-direction: column-reverse;
    }
`;

const CopyrightText = styled.div`
    flex: 2;

    font-family: 'Jost';
    font-size: 19px;
    color: white;

    text-align: left;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;

    @media only screen and (max-width: 768px) {
        font-size: 13px;
    }
`;

const Links = styled.div`
    flex: 1;

    display: flex;
    justify-content: end;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;
`;

const Button = styled.a`
    font-family: 'Jost';
    font-size: 19px;
    color: white;
    text-decoration: none;

    padding-left: 10px;
    padding-right: 10px;

    :hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 14px;
    }
`;

const Divider = styled.div`
    width: 1px;
    height: 10px;
    background-color: white;
`;

export default function Footer() {
    return (
        <ContainerDiv>
            <CopyrightText>© Copyright 2022 DeFiChain Accelerator. All rights reserved.</CopyrightText>
            <Links>
                <Button href={'/imprint'}>Imprint</Button>
                <Divider />
                <Button href={'/privacy'}>Privacy</Button>
                <Divider />
                <Button href={'https://www.defichain.com/'}>DeFiChain</Button>
            </Links>

        </ContainerDiv >
    );
}
