import React from 'react';
import TelegramLinkBar from './TelegramLinkBar';
import Title from '../Title';
import styled from 'styled-components';

const TWITTER_LINK = "https://twitter.com/defichainac";
const YOUTUBE_LINK = "https://www.youtube.com/c/DeFiChainAccelerator";
const TELEGRAM_ANNOUNCEMENTS_LINK = "https://t.me/defichainac_announcements";
const TELEGRAM_COMMUNITY_LINK = "https://t.me/defi_accelerator";

const ContainerDiv = styled.div`
    padding: 20px;

	flex: 1;

	display: flex;
	flex-direction: column;
	align-content: center;
	// justify-content: center;
	flex-wrap: wrap;
`;

const CenteredContainer = styled.div`
	// flex: 1;
	height: 309px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const LinksDiv = styled.div`
	display: flex;
    justify-content: center;
`;

const LinkImage = styled.img`
	:hover {
		cursor: pointer;
	}
`;

export default function LinksHolder() {
	return (
		<ContainerDiv>
			<Title title='Follow us' />
			<CenteredContainer>
				<TelegramLinkBar text='Announcements' link={TELEGRAM_ANNOUNCEMENTS_LINK}/>
				<TelegramLinkBar text='Community' link={TELEGRAM_COMMUNITY_LINK}/>
				<LinksDiv>
					<LinkImage width={55} src='/images/icons/twitter.svg' onClick={() => {
						window.open(TWITTER_LINK, '_blank')?.focus();
					}}/>
					<LinkImage width={55} src='/images/icons/youtube.svg' onClick={() => {
						window.open(YOUTUBE_LINK, '_blank')?.focus();
					}}/>
				</LinksDiv>
			</CenteredContainer>
		</ContainerDiv>
	);
}