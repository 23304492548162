
const animationProps = {
    animatePreScroll: true,
    initiallyVisible:false,
    animateOnce: false,
    animateIn: 'fadeInUp',
    animateOut: 'fadeOutDown',
    duration: 0.8,
    offset: 200,
    delay: 0,
}

export {
    animationProps
};
